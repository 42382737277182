/* eslint-disable global-require */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { Accordion } from '@latitude/accordion';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  BUTTON_STYLE,
  FONT_FAMILY
} from '@latitude/core/utils/constants';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '../../../components/PaymentOptions/PaymentOptions';

import Layout from '@/components/layout';
import { StickyNavigation } from '@latitude/sticky-navigation';

import { Heading3, Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import SvgInline from '@latitude/svg-inline';
import HowToApply from '@latitude/how-to-apply';
import { ListNumbered } from '@latitude/list-numbered';
import { List, ListItem } from '@latitude/list';
import { FeaturesSlider } from '@latitude/features-slider';

import Text from '@/components/Text/Text';
import { Strong } from '@/components/Text/Bold';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';

import footerData from './data/footer.json';
import PageData from '../../../data/pages/credit-cards/twenty-eight-degree.json';
import ArticleTile from '../../../templates/life-done-better/ArticleTile';

import { ImportantInformation } from '@latitude/important-information';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import bonusRewards from './images/herobanner-bonus-latitude-rewards.webp'
import monthlyCardFee from './images/herobanner-monthly-card-fees.webp'
import latitudeRewardsCarousel from './images/28degrees-rewards-carousel.svg'
import payLocal from './images/28d-pay-local.webp'
import DegreesCardMicrositeHeader from './_28d-microsite-header';

import FeatureCardList from '@/components/FeatureCardList/featureCardList';
import StandaloneText from '@/components/lab-components/StandaloneText';
import PromoBanner from '@/components/lab-components/PromoBanner';
import BenifitsOfDegrees from './benefits-of-28d';
import PromoCodeHandler from '@/components/PromoCodeHandler';
import { CARD_CODE, CARD_DATA } from '@/data/pages/credit-cards/card-offer';
import { AnalyticsContext } from 'latitude-analytics';

const APPLY_URL = 'https://cards.latitudefinancial.com/28degrees/'

const KEEP_EXPLORING_INFO = [
  {
    title: 'Find your inspiration',
    thumbnail: 'find-your-inspiration.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Plan your trip',
    thumbnail: 'plan-your-trip.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Calculate your savings',
    thumbnail: 'calculate-your-savings.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  }
];

// Card data array
const cardData = CARD_DATA.filter((card) => card.code !== CARD_CODE['28D'])

const schemaData = {
  "@context": "https://schema.org",
  "contactlessPayment":"true",
  "@type": "CreditCard",
  "annualPercentageRate": "27.99",
  "image": "https://assets.latitudefinancial.com/legals/key-fact-sheet/28-degrees/card-28-degrees.png",
  "url": "https://www.latitudefinancial.com.au/credit-cards/28-degrees/",
  "category":"Latitude Travel Credit Cards",
  "brand":{"@type":"Brand","name":"Latitude Financial Services"},
  "amount":{"@type":"MonetaryAmount","minValue":"1000","currency": "AUD"},
  "name": "Latitude 28° Global Platinum Mastercard",
  "description": "Shop with no international transaction or currency conversion fees on purchases online or overseas. Get free global data roaming, ecommerce protection, travel offers and more.",
  "requiredCollateral": "$1000 minimum credit limit"
}

const DegreesCardPage = props => {

  const gatsbyTealiumEnv = process.env.GATSBY_TEALIUM_ENV || 'unknown';
  const dynamicYieldKey = gatsbyTealiumEnv === 'prod' ? '8790471' : '8790470';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  console.log({ state });
  
  /** - END - */

      //we can apply this url to CTA
const promoApplyURLRewards = PromoCodeHandler({
  baseApplyURL:APPLY_URL,
  defaultPromoCode: "2WPFA6DJ",
  defaultSourceCode: "IDAAC",
  storageKey: "latitude_28degree_rewards_source_code" // Unique key
});

const promoApplyURLMonthlyfee= PromoCodeHandler({
  baseApplyURL:APPLY_URL,
  defaultPromoCode: "HHJEQCUU",
  defaultSourceCode: "IDAAC",
  storageKey: "latitude_28degree_monthly_source_code" // Unique key
});

const latitudeRewardsTitle = "Hero Banner Slide - Get $300 bonus Latitude Rewards"
const monthlyCardFeeTitle= "Hero Banner Slide - Enjoy $0 monthly card fee"
const latitudeRewardsTitlePromo='Promo Card -- Get $300 Bonus Latitude Rewards'
const monthlyCardFeeTitlePromo='Promo Card -- Enjoy $0 monthly card fee'

const HEADER_ITEMS = [
  {
    title: 'Get $300 Bonus Latitude Rewards<sup>5</sup>',
    text:'When you spend $1,000 or more on eligible transactions every statement period in your first 3 months from approval.',
    termsconds: 'T&Cs, monthly credit card fee, and other charges apply. New customers only, must be approved by 28 May 2025.​',
    headerImage: bonusRewards,
    urlFindOutMore: '',
    urlApplyNow: promoApplyURLRewards
  },
  {
    title:'Enjoy $0 monthly card fee<sup>6</sup>',
    text:'For your first 12 months from approval with Latitude 28° Global Platinum Mastercard credit card.​',
    termsconds: 'T&Cs and other charges apply. New customers only, must be approved by 28 May 2025.​',
    headerImage: monthlyCardFee,
    urlFindOutMore: '',
    urlApplyNow:promoApplyURLMonthlyfee
  },
  {
    title:'Shop and be rewarded with the Latitude Rewards Program',
    text:'Enjoy up to $120 worth of Latitude Rewards each year which you can redeem for e-gift cards from participating retailers when you spend $1,000 or more on eligible transactions in every statement period for 12 months​',
    termsconds: '​',
    headerImage:latitudeRewardsCarousel,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees/'
  },
  { 
    title:'Pay like a local.  Online and overseas.',
    text:'Save with $0 international transaction or currency conversion fees on purchases, and great foreign exchange rates set by Mastercard so you get foreign currencies at the same rate we do.​',
    termsconds: 'T&Cs, card fee and other charges apply.​',
    headerImage: payLocal,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees/'
  }
];

const [analytics] = useContext(AnalyticsContext);

// adding pageData to analytics
analytics.pageData = {
  page_pageTitle: '28-degrees',
  page_pageType: 'product-details-page',
  page_siteSection: 'cards',
  page_siteSubsection: '28-degrees',
  product_productid: ["CCAU28D"]
};

//Manually append the dynamic promo url to the contentful
if (state) {
  const slides = state?.heroBannerData?.[0]?.slides;
  const promoCards = state?.promoBannerData?.[1]?.promoCards;

  if (slides) {
    slides.forEach(slide => {
      switch (slide.name) {
        case latitudeRewardsTitle:
          slide.buttons[0].url = promoApplyURLRewards
          break;
        case monthlyCardFeeTitle:
          slide.buttons[0].url = promoApplyURLMonthlyfee
          break;
      }
    })
  }
  if (promoCards) {
    promoCards.forEach(promoCard => {
      switch (promoCard.name) {
        case latitudeRewardsTitlePromo:
          promoCard.primaryButton.url = promoApplyURLRewards
          break;
        case monthlyCardFeeTitlePromo:
          promoCard.primaryButton.url = promoApplyURLMonthlyfee
          break;
      }
    })
  }
}


  return (
    <Layout customFooter={footerData} location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 10px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }

          [class^='HowToApply__StyledConfetti'],
          [class^='RatesListBranded__RatesList-']::before,
          [class^='RatesListBranded__RatesList-']::after {
            display: none;
          }
        `}
      >
        <Helmet defer={false}>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/"
          />
          <title>28° Platinum Mastercard Credit Card | No Foreign Transaction Fees</title>
          <meta
            name="description"
            content="Travel smart & shop online or overseas with Latitude 28° Mastercard. $0 international transaction or currency conversion fees on purchases and exclusive travel perks"
          />

          <link rel="preconnect" href="//cdn.dynamicyield.com" />
          <link rel="preconnect" href="//st.dynamicyield.com" />
          <link rel="preconnect" href="//rcom.dynamicyield.com" />
          <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
          <link rel="dns-prefetch" href="//st.dynamicyield.com" />
          <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          <script type="text/javascript">
            {`
    window.DY = window.DY || {};
    DY.recommendationContext = { type: "PRODUCT", data: ['28-degrees'] };
  `}
          </script>
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_dynamic.js`}
          />
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_static.js`}
          />
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        </Helmet>
        <div css={`[class*=MicrositeHeader__CtaButton]{
          display: none ;}`}><DegreesCardMicrositeHeader data={state.secondaryNavData} /> </div>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} headingSize="Heading 2" />
        ) : (
          <div css={`
            @media (max-width: 768px) {
              .pageHeader, .pageHeaderItemText , .pageHeaderItemText h1 {
                background: linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%) !important ;
                color: #fff !important;
              }
            }
            `}>
            <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
            </div> 
        )}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigation
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'latest-offers',
                label: 'Offers'
              },
              {
                anchor: 'rates',
                label: 'Rates & Fees'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'faq',
                label: 'FAQ'
              }
            ]}
            ctaText="Get Rate Estimate"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {state?.promoBannerData?.[1] ? (
          <PromoBanner {...state?.promoBannerData[1]} />
        ): (<BenifitsOfDegrees  applyUrlRewards={promoApplyURLRewards} applyUrlMonthlyfee={promoApplyURLMonthlyfee}  />)}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            contentAlign={state?.featureSliderData?.[0].contentAlign}
            backgroundColor={COLOR.WHITE}
            css={`
              && {
                padding: 40px 15px 0;
              }
            `}
          />
        )}

        {state?.standaloneTextData?.[0] && (
          <StandaloneText 
            text={state?.standaloneTextData?.[0].text}
            align={state?.standaloneTextData?.[0].align}
            background={state?.standaloneTextData?.[0].background}
            styles={`
              ul {
                padding-left: 10px;
                margin: 0;
              }
              ul p {
                margin-bottom: 5px;
                padding: 0;
              }
              p {
                font-size: 16px;
                margin-top: 0;
                padding-left: 9px;
              }
              h5 {
                margin-top: 0;
              }
            `}
          />
        )}

        {state?.ratesAndFeesData?.[0] && (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        )}

        <HowToApply
          id="how-to-apply"
          box1={
            <div
              css={`
                .list-numbered li {
                  align-items: flex-start;
                  color: ${COLOR.BLACK};
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                How to apply
              </Heading4>
              <ListNumbered
                data={[
                  `<span>
                      <strong style="font-weight:500;">Apply online</strong> <br /> You can check your
                      eligibility and apply in 10 minutes.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Wait a minute</strong> <br /> 60 seconds is all it takes for us to respond to your application.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Ready, set, shop</strong> <br /> Simply activate your card when it arrives to start shopping and reaping the rewards.</span>
                    </span>`
                ]}
                viewStyle="compact"
                separator="none"
                counterBgColor={COLOR.BLUE_DEEP}
              />
            </div>
          }
          box2={
            <div
              css={`
                && {
                  p {
                    color: ${COLOR.BLACK};
                  }
                  ul {
                    color: ${COLOR.BLACK};
                    margin-left: 24px;
                    li:before {
                      line-height: 0.85em;
                    }
                  }
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                Eligibility
              </Heading4>
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                You must:
              </Text>
              <List>
                <ListItem>Be 18 years or over</ListItem>
                <ListItem>Be a permanent Australian resident</ListItem>
              </List>
              <br />
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                Able to supply
              </Text>
              <List>
                <ListItem>
                  A copy of your driver’s licence, passport or proof of age card
                </ListItem>
                <ListItem>Details of your income, assets and debts</ListItem>
                <ListItem>Employer’s name and contact details</ListItem>
              </List>
            </div>
          }
        />

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        <Section
          heading="Still looking for other options? Explore our other credit cards"
          id="other-cards-section"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
              h2{
              font-size:32px;
              }  
            }
          `}
        >
          <FeatureCardList cardList={cardData} />
        </Section>

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Latitude Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude 28° Global Platinum Mastercard Biller Code
                            (150615)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <Box.Section
          backgroundColor={COLOR.GREY5}
          paddingTop={MARGIN.M32}
          paddingBottom={MARGIN.M32}
        >
          <AnalyticsLocationProvider location="Keep exploring">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Keep exploring
            </Heading4>
            <CardGroup>
              {KEEP_EXPLORING_INFO.map(item => (
                <CardGroupItem key={item.title}>
                  <ArticleTile target="_self" isBranded {...item} />
                </CardGroupItem>
              ))}
            </CardGroup>
          </AnalyticsLocationProvider>
        </Box.Section>

        <Box.Section
          id="faq"
          css={`
            background-color: ${COLOR.WHITE};
            @media (min-width: ${BREAKPOINT.LG}) {
              width: 50%;
            }
          `}
        >
          <div data-contentful={state?.faqData?.[0]?.contentfulID}>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              paddingBottom={MARGIN.M64}
            >
              {state?.faqData?.[0]?.title || 'Frequently asked questions'}
            </Heading4>
            <AnalyticsLocationProvider
              location={
                state?.faqData?.[0]?.title || 'frequently asked questions'
              }
            >
              <Accordion
                css={`
                  .accordion__item-body {
                    padding: 16px 24px;
                    background-color: #f8f8f8 !important;
                  }
                `}
                titleBgColor="grey-light"
                items={state?.faqData?.[0]?.data || PageData.faq}
              />
            </AnalyticsLocationProvider>
            <Link
              button={BUTTON_STYLE.TERTIARY}
              css="width: 100%; margin: 64px auto; width: 320px"
              target='_self'
              href={
                state?.faqData?.[0]?.cta?.url ||
                '/credit-cards/28-degrees/faqs/'
              }
              data-trackid="see-all-faq"
              trackEventData={{
                label: state?.faqData?.[0]?.cta?.text || 'See all FAQ'
              }}
            >
              {state?.faqData?.[0]?.cta?.text || 'See all FAQ'}
            </Link>
          </div>
        </Box.Section>

        <ImportantInformation
          id="important-Info"
          data={{
            content: {
              importantInformationSectionOne: [
                '# To take advantage of up to 55 days Interest Free on everyday credit card purchases, you need to pay the full closing balance on each statement of account by the applicable due date.',
                "<sup id='note-1'>1</sup> Latitude 28&deg; Global Platinum Mastercard cardholders will be eligible for a $10 Latitude Reward every statement period which is redeemable for an e-gift card from participating retailers when they spend $1,000 or more on eligible transactions using their credit card within the statement period. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default of the credit contract when the Latitude Rewards is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days from the end of the statement period. The primary cardholder has up to 18 months to redeem from the date of issue. <a href='https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf' target='_blank'>View full terms and conditions.</a>'",
                '<sup id="note-2">2</sup> Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary Purchase Protection Insurance are the current holders of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. <a targe="_blank" href="https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf">View full terms and conditions</a>.',
                '<sup id="note-3">3</sup> E-Commerce Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary E-Commerce Purchase Protection Insurance are the current holder of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these e-commerce purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard E-Commerce Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. <a targe="_blank" href="https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf">View full terms and conditions</a>.'
              ],
              importantInformationSectionTwo: [
                '<sup id="note-4">4</sup> The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.',
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.',
                'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.',
                'Samsung and Samsung Pay are trademarks or registered trademarks of Samsung Electronics Co., Ltd.',
                'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S. Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.',
                'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.',
                '&reg; Registered to BPAY Pty Ltd ABN 69 079 137 518',
                "<sup id='note-5'>5</sup>$300 Latitude Reward is available to new and approved applicants by 28 May 2025 who apply for a Latitude 28° Global Platinum Mastercard and is limited to one offer per applicant. To qualify, you must be approved and fulfil on the terms of the offer by spending $1,000 or more on eligible transactions each statement period for 3 months from the date of your approval. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default or the credit contract when the redeemable amount is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days of meeting the spend criteria. The primary cardholder has up to 18 months to redeem from the date of issue. Latitude may vary, extend or withdraw this offer at any time without notice.",
                "<sup id='note-6'>6</sup>$0 Monthly Card fees for your first 12 months is available to new and approved applicants by 28 May 2025 who apply for a Latitude 28° Global Platinum Mastercard and is limited to one offer per applicant. After the first year, the monthly card fee reverts to $8 per month (subject to change) and will be debited to your card account on your first statement following your 12-month anniversary and monthly thereafter. Latitude may vary, extend or withdraw this offer at any time without notice.​"
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

export default DegreesCardPage;
